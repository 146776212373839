@import 'src/global/mixins.scss';

.markdown {
  @include container(var(--global-space-single));
  color: black;
  text-align: left;
  font-size: 14px;

  strong {
    font-weight: 700;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }

  ul,
  ol {
    padding-left: var(--global-space-double);
  }

  a {
    color: var(--global-color-blue);
    text-decoration: none;
  }

  pre,
  code {
    @include rounded;
    background-color: white;
    border: 1px solid var(--global-color-gray-7);
    padding: 2px 4px;
    font-family: monospace;
    color: var(--global-color-es-gray-dark);
    font-size: 11px;
  }
}
