@import 'src/global/mixins.scss';

.sourceBadge {
  @include flex-center;
  @include rounded;
  border: 1px solid var(--global-color-gray-4);
  max-width: var(--source-badge-max-width);

  .sourceBadgeButtonLinkWrapper {
    flex-shrink: 1;
    overflow: hidden;
  }

  .sourceBadgeButton {
    @include flex-center;
    gap: var(--global-space-half);
    padding: var(--global-space-half) var(--global-space-single);
    max-width: 100%;

    .sourceTitle {
      @include ellipsis;
      color: var(--global-color-es-gray-dark);
      font-size: 12px;
      font-weight: 400;
      flex-shrink: 1;
    }

    .externalLinkIcon {
      width: 0;
      opacity: 0;
    }
  }

  .divider {
    width: 0;
    opacity: 0;
    height: 26px;
    flex-shrink: 0;
    background-color: var(--global-color-gray-7);
  }

  .summarizeButton {
    display: flex;

    .sparklesIcon {
      width: 0;
      opacity: 0;
    }
  }

  &:hover,
  &.selected {
    background-color: var(--global-color-light-blue);

    .externalLinkIcon,
    .sparklesIcon {
      width: 10px;
      opacity: 1;
      color: var(--global-color-blue);
      flex-shrink: 0;
    }

    .divider {
      width: 1px;
      opacity: 1;
    }

    .summarizeButton {
      padding: var(--global-space-half) var(--global-space-single);
    }
  }

  &.selected .sparklesIcon {
    color: var(--global-color-dark-blue);
  }

  .summarizeButton:hover {
    .sparklesIcon {
      color: var(--global-color-dark-blue);
    }
  }
}

.summaryTooltip {
  min-height: 110px;
  max-height: 300px;
  min-width: 525px;
  max-width: 525px;
  padding: var(--global-space-single);
  background-color: var(--global-color-gray-5);
}
